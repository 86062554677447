import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { setConfigurationForPipelinesOfDelivery, setJiraComponentMappingsForPipelines } from './configuration.actions';
import { map } from 'rxjs/operators';
import { JiraAllComponentPipelineMapping } from '../../../shared/access-api/access-api.service';
import { getAllJiraComponentPipelineMappingsFlat } from './configuration.utils';

@Injectable()
export class ConfigurationEffects {
  constructor(
    private readonly action$: Actions,
    private readonly store$: Store,
  ) {}

  public onSetConfigurationToJiraComponentMapping = createEffect(() => {
    return this.action$.pipe(
      ofType(setConfigurationForPipelinesOfDelivery),
      map((action) => {
        const allPipelineMappings: JiraAllComponentPipelineMapping[] = getAllJiraComponentPipelineMappingsFlat(
          Object.values(action.config),
        );
        return setJiraComponentMappingsForPipelines({ jiraComponentMapping: allPipelineMappings });
      }),
    );
  });
}
