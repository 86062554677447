<div
  *ngIf="{
    isLoading: isLoading$ | async,
    isLoadingError: isLoadingError$ | async,
    dataSource: dataSource$ | async,
  } as data"
  class="flex flex-col"
  mat-dialog-content
>
  <div class="flex h-full w-full">
    <app-dialog-error-information (reloadClicked)="reload()" *ngIf="data?.isLoadingError"></app-dialog-error-information>
    <kendo-treelist
      class="flex h-full w-full"
      id="policy-usage-group-detail-grid"
      data-test-id="policy-usage-aggregated-detail-popup-grid"
      [kendoTreeListHierarchyBinding]="dataSource.data"
      childrenField="subPolicies"
      kendoTreeListExpandable
      [height]="410"
      *ngIf="!data.isLoadingError"
      [resizable]="true"
      [sortable]="{ mode: 'multiple' }"
      [sort]="gridState.sort"
      [filterable]="true"
      [filter]="gridState.filter"
    >
      <kendo-treelist-column [expandable]="true" field="policyText" title="Policy" class="vertical-align-initial td-padding-top-20"  [width]="250"
      [minResizableWidth]="100"  filter="text">
        <ng-template kendoTreeListCellTemplate kendoTooltip let-dataItem>
          <div class="align-middle treelist-first-cell-wrapper" data-test-id="policyText-column">
            <div
              matBadge="{{ dataItem.subPolicies?.length }}"
              matBadgeSize="small"
              matBadgeOverlap="false"
              class="badge-container"
              [matBadgeHidden]="!dataItem.subPolicies"
            >
              <a [fusionTooltip]="'Open Policy'" href="{{ dataItem?.policyUrl }}" rel="noopener noreferrer" target="_blank">
                <span data-test-id="policy-usage-policy-key" class="bold">{{ dataItem.policyKey }}</span>
                <i class="fi fi-link-external bold policyExternalLinkIcon"></i>
              </a>
            </div>
            <div class="policyLabel" data-test-id="policy-usage-policy-label">{{ dataItem.policyLabel }}</div>
          </div>
        </ng-template>
      </kendo-treelist-column>

      <kendo-treelist-column
        field="compliantPipelineCount"
        title="Compliant Pipelines"
        class="text-align-right"
        headerClass="text-align-right"
        [width]="180"
      >
        <ng-template kendoTreeListCellTemplate let-dataItem>
          <div data-test-id="compliantPipelineCount-column">
            {{ dataItem.compliantPipelineCount }}
          </div>
        </ng-template>
        <ng-template kendoTreeListFilterCellTemplate let-filter let-column="column">
          <kendo-treelist-numeric-filter-cell [column]="column" [filter]="filter" [spinners]="false" [min]="0" format="#">
          </kendo-treelist-numeric-filter-cell>
        </ng-template>
      </kendo-treelist-column>

      <kendo-treelist-column
        field="notCompliantPipelineCount"
        title="Not Compliant Pipelines"
        class="text-align-right"
        headerClass="text-align-right"
        [width]="180"
      >
        <ng-template kendoTreeListCellTemplate let-dataItem>
          <div data-test-id="notCompliantPipelineCount-column" [ngClass]="{ redColor: dataItem.notCompliantPipelineCount > 0 }">
            {{ dataItem.notCompliantPipelineCount }}
          </div>
        </ng-template>

        <ng-template kendoTreeListFilterCellTemplate let-filter let-column="column">
          <kendo-treelist-numeric-filter-cell [column]="column" [filter]="filter" [spinners]="false" [min]="0" format="#">
          </kendo-treelist-numeric-filter-cell>
        </ng-template>
      </kendo-treelist-column>

      <kendo-treelist-column
        field="noDataAvailablePipelineCount"
        title="Pipelines w/o Information"
        class="text-align-right"
        headerClass="text-align-right"
        [width]="180"
      >
        <ng-template kendoTreeListCellTemplate let-dataItem>
          <div data-test-id="noDataAvailablePipelineCount-column">
            {{ dataItem.noDataAvailablePipelineCount }}
          </div>
        </ng-template>

        <ng-template kendoTreeListFilterCellTemplate let-filter let-column="column">
          <kendo-treelist-numeric-filter-cell [column]="column" [filter]="filter" [spinners]="false" [min]="0" format="#">
          </kendo-treelist-numeric-filter-cell>
        </ng-template>
      </kendo-treelist-column>

      <kendo-treelist-column [width]="180">
        <ng-template kendoTreeListCellTemplate let-dataItem>
          <a
            data-test-id="policy-usage-group-details-popup-view-details-link"
            class="flex flex-row justify-end items-center"
            (click)="selectedPolicy.emit(dataItem)"
            fusionFeatureUsageTracker="policy-usage-group-detail-dialog-view-details"
          >
            <span>View Details</span>
            <fusion-icon class="forwardButton" name="chevron-right" size="XL"></fusion-icon>
          </a>
        </ng-template>
      </kendo-treelist-column>
    </kendo-treelist>
    <app-table-loading-indicator *ngIf="data.isLoading" class="custom-loading-indicator-position"></app-table-loading-indicator>
  </div>
</div>
