import { CommonModule, NgIf, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { TooltipModule as KendoTooltipModule, PopoverModule, TooltipsModule } from '@progress/kendo-angular-tooltip';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { ButtonModule, FeatureUsageTrackerModule, IconModule, TooltipModule } from '@tools/fusion';
import { NgxGaugeModule } from 'ngx-gauge';
import { ChangeCurrentStateDialogComponent } from '../change-pipeline-run-release-status-dialog/change-current-state-dialog/change-current-state-dialog.component';
import { RevertConfirmationDialogComponent } from '../change-pipeline-run-release-status-dialog/revert-confirmation-dialog/revert-confirmation-dialog.component';
import { DeprecationPopoverComponent } from '../deprecation-popover/deprecation-popover.component';
import { GroupComplianceMode } from '../shared/model/group-compliance-mode';
import { SharedModule } from '../shared/shared.module';
import { KpiColumnComplianceStatusComponent } from './columns/kpi-column-compliance-status/kpi-column-compliance-status.component';
import { KpiColumnPipelineComponent } from './columns/kpi-column-pipeline/kpi-column-pipeline.component';
import { ComplianceStatusLabelComponent } from './compliance-status-label/compliance-status-label.component';
import { ComplianceStatusTooltipComponent } from './compliance-status-tooltip/compliance-status-tooltip.component';
import { CustomPolicyUsageAggregationDetailsComponent } from './list-item-kpi-policy-usage-aggregation/custom-policy-usage-aggregation-details/custom-policy-usage-aggregation-details.component';
import { CentralPolicyResultsComponent } from './list-item-kpi-policy-usage-aggregation/list-item-kpis-policy-results-details/central-policy-results/central-policy-results.component';
import { CustomPolicyResultsComponent } from './list-item-kpi-policy-usage-aggregation/list-item-kpis-policy-results-details/custom-policy-results/custom-policy-results.component';
import { ListItemKpisPolicyUsageAggregationDialogComponent } from './list-item-kpi-policy-usage-aggregation/list-item-kpis-policy-usage-aggregation-dialog/list-item-kpis-policy-usage-aggregation-dialog.component';
import { ListItemKpisPolicyUsageAggregationComponent } from './list-item-kpi-policy-usage-aggregation/list-item-kpis-policy-usage-aggregation.component';
import { PolicyUsageAggregationDetailsComponent } from './list-item-kpi-policy-usage-aggregation/policy-usage-aggregation-details/policy-usage-aggregation-details.component';
import { ApiMetadataValidatorDetailsDialogComponent } from './list-item-kpis-api-metadata-validator/api-metadata-validator-details-dialog/api-metadata-validator-details-dialog.component';
import { ListItemKpisApiMetadataValidatorComponent } from './list-item-kpis-api-metadata-validator/list-item-kpis-api-metadata-validator.component';
import { ListItemKpisAtcComponent } from './list-item-kpis-atc/list-item-kpis-atc.component';
import { CoverageDetailsDialogComponent } from './list-item-kpis-coverage/coverage-details-dialog/coverage-details-dialog.component';
import { ListItemKpisCoverageComponent } from './list-item-kpis-coverage/list-item-kpis-coverage.component';
import { DocumentsDetailsDialogComponent } from './list-item-kpis-documents/documents-details-dialog/documents-details-dialog.component';
import { ListItemKpisDocumentsComponent } from './list-item-kpis-documents/list-item-kpis-documents.component';
import { Fc1DetailsDialogComponent } from './list-item-kpis-fc1/fc1-details-dialog/fc1-details-dialog.component';
import { ListItemKpisFc1Component } from './list-item-kpis-fc1/list-item-kpis-fc1.component';
import { RequirementsDetailDialogComponent } from './list-item-kpis-fc2/dialog/requirements-detail-dialog.component';
import { ExcelExportComponent } from './list-item-kpis-fc2/dialog/requirements/excel-export/excel-export.component';
import { LightweightTestingComponent } from './list-item-kpis-fc2/dialog/requirements/lightweight-testing/lightweight-testing.component';
import { RequirementsComponent } from './list-item-kpis-fc2/dialog/requirements/requirements.component';
import { TestsComponent } from './list-item-kpis-fc2/dialog/tests/tests.component';
import { ListItemKpisFc2Component } from './list-item-kpis-fc2/list-item-kpis-fc2.component';
import { Fc3DetailsDialogComponent } from './list-item-kpis-fc3/fc3-details-dialog/fc3-details-dialog.component';
import { ListItemKpisFc3Component } from './list-item-kpis-fc3/list-item-kpis-fc3.component';
import { FosstarDetailsDialogComponent } from './list-item-kpis-fosstar/fosstar-details-dialog/fosstar-details-dialog.component';
import { ListItemKpisFosstarComponent } from './list-item-kpis-fosstar/list-item-kpis-fosstar.component';
import { IPScanDetailsDialogComponent } from './list-item-kpis-ip-scan/ip-scan-details-dialog/ip-scan-details-dialog.component';
import { ListItemKpisIPScanComponent } from './list-item-kpis-ip-scan/list-item-kpis-ip-scan.component';
import { ListItemKpisMalwarescanComponent } from './list-item-kpis-malwarescan/list-item-kpis-malwarescan.component';
import { MalwarescanDetailsDialogComponent } from './list-item-kpis-malwarescan/malwarescan-details-dialog/malwarescan-details-dialog.component';
import { CustomPolicyResultAggregationDetailsComponent } from './list-item-kpis-policy-result-aggregation/custom-policy-result-aggregation-details/custom-policy-result-aggregation-details.component';
import { ListItemKpisPolicyResultAggregationDialogComponent } from './list-item-kpis-policy-result-aggregation/list-item-kpis-policy-result-aggregation-dialog/list-item-kpis-policy-result-aggregation-dialog.component';
import { ListItemKpisPolicyResultAggregationComponent } from './list-item-kpis-policy-result-aggregation/list-item-kpis-policy-result-aggregation.component';
import { PolicyResultAggregationDetailsComponent } from './list-item-kpis-policy-result-aggregation/policy-result-aggregation-details/policy-result-aggregation-details.component';
import { CustomPolicyResultsDetailsComponent } from './list-item-kpis-policy-results/custom-policy-results-details/custom-policy-results-details.component';
import { ListItemKpisPolicyResultsDialogComponent } from './list-item-kpis-policy-results/list-item-kpis-policy-results-dialog/list-item-kpis-policy-results-dialog.component';
import { ListItemKpisPolicyResultsComponent } from './list-item-kpis-policy-results/list-item-kpis-policy-results.component';
import { PolicyResultsDetailsComponent } from './list-item-kpis-policy-results/policy-results-details/policy-results-details.component';
import { ValidationErrorMessageListComponent } from './list-item-kpis-policy-results/validation-error-message-list/validation-error-message-list.component';
import { ListItemKpisRegressionComponent } from './list-item-kpis-regression/list-item-kpis-regression.component';
import { ListItemKpisRequirementsComponent } from './list-item-kpis-requirements/list-item-kpis-requirements.component';
import { ListItemKpisSlc25Component } from './list-item-kpis-slc25/list-item-kpis-slc25.component';
import { Slc25AllowedListDialogComponent } from './list-item-kpis-slc25/slc25-allowed-list-dialog/slc25-allowed-list-dialog.component';
import { Slc25DetailsDialogComponent } from './list-item-kpis-slc25/slc25-details-dialog/slc25-details-dialog.component';
import { ListItemKpisSonarqubeCoverageComponent } from './list-item-kpis-sonarqube-coverage/list-item-kpis-sonarqube-coverage.component';
import { SonarqubeCoverageDetailsDialogComponent } from './list-item-kpis-sonarqube-coverage/sonarqube-coverage-details-dialog/sonarqube-coverage-details-dialog.component';
import { ListItemKpisComponent } from './list-item-kpis/list-item-kpis.component';
import { MalwarescanStatusLabelComponent } from './malwarescan-status-label/malwarescan-status-label.component';

const components = [
  ListItemKpisComponent,
  ListItemKpisRequirementsComponent,
  ListItemKpisSlc25Component,
  ListItemKpisFosstarComponent,
  ListItemKpisIPScanComponent,
  ListItemKpisFc3Component,
  ListItemKpisCoverageComponent,
  CoverageDetailsDialogComponent,
  RequirementsDetailDialogComponent,
  RequirementsComponent,
  TestsComponent,
  LightweightTestingComponent,
  FosstarDetailsDialogComponent,
  Slc25DetailsDialogComponent,
  Slc25AllowedListDialogComponent,
  IPScanDetailsDialogComponent,
  Fc3DetailsDialogComponent,
  ListItemKpisFc3Component,
  Fc1DetailsDialogComponent,
  ListItemKpisFc1Component,
  ListItemKpisFc2Component,
  ListItemKpisAtcComponent,
  ComplianceStatusLabelComponent,
  ComplianceStatusTooltipComponent,
  ListItemKpisRegressionComponent,
  ListItemKpisSonarqubeCoverageComponent,
  SonarqubeCoverageDetailsDialogComponent,
  ExcelExportComponent,
  KpiColumnPipelineComponent,
  KpiColumnComplianceStatusComponent,
  ListItemKpisMalwarescanComponent,
  MalwarescanDetailsDialogComponent,
  MalwarescanStatusLabelComponent,
  DocumentsDetailsDialogComponent,
  ListItemKpisPolicyResultsDialogComponent,
  ListItemKpisPolicyResultsComponent,
  ListItemKpisPolicyResultAggregationComponent,
  ValidationErrorMessageListComponent,
  ListItemKpisPolicyResultAggregationDialogComponent,
  PolicyResultsDetailsComponent,
  CustomPolicyResultsDetailsComponent,
  PolicyResultAggregationDetailsComponent,
  CustomPolicyResultAggregationDetailsComponent,
  DeprecationPopoverComponent,
  ListItemKpisPolicyUsageAggregationComponent,
  ListItemKpisPolicyUsageAggregationDialogComponent,
  PolicyUsageAggregationDetailsComponent,
  CustomPolicyUsageAggregationDetailsComponent,
  CustomPolicyResultsComponent,
  CentralPolicyResultsComponent,
  ChangeCurrentStateDialogComponent,
  RevertConfirmationDialogComponent,
];

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatChipsModule,
    TooltipModule,
    IconModule,
    NgxGaugeModule,
    GridModule,
    FeatureUsageTrackerModule,
    ButtonModule,
    SharedModule,
    TranslateModule,
    KendoTooltipModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTableModule,
    ExcelModule,
    TooltipsModule,
    MatTabsModule,
    BrowserModule,
    BrowserAnimationsModule,
    PopoverModule,
    NgOptimizedImage,
    FormsModule,
    NgIf,
    MatButtonModule,
    ReactiveFormsModule,
    MatBadgeModule,
    TreeListModule,
  ],
  declarations: [
    components,
    ListItemKpisDocumentsComponent,
    ListItemKpisApiMetadataValidatorComponent,
    ApiMetadataValidatorDetailsDialogComponent,
  ],
  exports: [components, ListItemKpisDocumentsComponent, ListItemKpisApiMetadataValidatorComponent],
})
export class KpisModule {}

export interface KpiDialogsData {
  pipelineRunId?: number;
  deliveryId?: string;
  groupId?: string;
  groupComplianceMode?: GroupComplianceMode;
  isCustomPolicyResults?: boolean;
  isCustomPolicyUsage?: boolean;
}
