export enum PolicyComplianceStatusKey {
  NOT_COMPLIANT = 'NOT_COMPLIANT',
  COMPLIANT = 'COMPLIANT',
  IGNORED = 'IGNORED',
}

export class PolicyComplianceStatus {
  static NOT_COMPLIANT = new PolicyComplianceStatus(PolicyComplianceStatusKey.NOT_COMPLIANT, 'Not Compliant', 1);
  static COMPLIANT = new PolicyComplianceStatus(PolicyComplianceStatusKey.COMPLIANT, 'Compliant', 2);
  // edge case if no compliance status is available
  static NOT_AVAILABLE = new PolicyComplianceStatus(null, 'n/a', 99);
  static IGNORED = new PolicyComplianceStatus(PolicyComplianceStatusKey.IGNORED, 'Ignored', 100);

  constructor(
    public key: PolicyComplianceStatusKey,
    public displayKey: string,
    public statusSortOrder: number,
  ) {}

  static getByKey(key: string) {
    return Object.values(PolicyComplianceStatus).find((entry) => entry.key == key) ?? PolicyComplianceStatus.NOT_AVAILABLE;
  }
}

export class CustomPolicyComplianceStatus {
  static NOT_COMPLIANT = new CustomPolicyComplianceStatus(PolicyComplianceStatusKey.NOT_COMPLIANT, 'Not Compliant', 1);
  static COMPLIANT = new CustomPolicyComplianceStatus(PolicyComplianceStatusKey.COMPLIANT, 'Compliant', 2);
  // edge case if no compliance status is available
  static NOT_AVAILABLE = new CustomPolicyComplianceStatus(null, 'n/a', 99);

  constructor(
    public key: PolicyComplianceStatusKey,
    public displayKey: string,
    public statusSortOrder: number,
  ) {}

  static getByKey(key: string) {
    return Object.values(PolicyComplianceStatus).find((entry) => entry.key == key) ?? PolicyComplianceStatus.NOT_AVAILABLE;
  }
}

export interface PolicyResultsPipelineRunDetail {
  policyResultId: number;
  policyComplianceStatus: PolicyComplianceStatus;
  policyKey: string;
  policyLabel: string;
  policyText?: string;
  policyUrl: string;
  policyOwner: string;
  policyOwnerSubstitute: string;
  subPath?: string;
  fileName: string;
  toolName?: string;
  validationErrorMessages: string[];
  dependsOnPolicyResultKeys?: string[];
  subPolicies?: PolicyResultsPipelineRunDetail[];
}

export interface CustomPolicyResultsPipelineRunDetail {
  customPolicyResultId: number;
  subPath: string;
  fileName: string;
  customPolicyResultStatus: string;
  customPolicyComplianceStatus: CustomPolicyComplianceStatus;
  customPolicyKey: string;
  customPolicyVersion: string;
  customPolicyLabel: string;
  customPolicyAuthor: string;
  customPolicySourceUrl: string;
  customPolicyDescription: string;
  customPolicyText: string;
  validationErrorMessages: string[];
  toolUrl?: string;
}

export interface PolicyResultsPipelineRunSummary {
  policyTotalAmount: number;
  policyCompliantAmount: number;
}

export interface PolicyResultsGroupSummary {
  pipelinesWithPoliciesAmount: number;
  pipelinesWithOnlyCompliantPoliciesAmount: number;
}

export interface PolicyResultsGroupDetails {
  pipelineId: string;
  pipelineKey: string;
  pipelineUrl: string;
  pipelineRunId: number;
  buildUrl: string;
  policyTotalAmount: number;
  policyCompliantAmount: number;
  policyNotCompliantAmount: number;
}

export interface GroupPolicyUsageSummary {
  policyCount: number;
  groupComplianceStatus: string;
}

export interface GroupCustomPolicyUsageSummary {
  customPolicyCount: number;
  groupCustomPolicyResultStatus: string;
}

export interface GroupPolicyUsageByPolicyKey {
  policyKey: string;
  policyLabel: string;
  policyDescription: string;
  policyUrl: string;
  compliantPipelineCount: number;
  notCompliantPipelineCount: number;
  noDataAvailablePipelineCount: number;
  policyText?: string;
  dependsOnPolicyResultKeys?: string[];
  subPolicies?: GroupPolicyUsageByPolicyKey[];
}

export interface GroupCustomPolicyUsageByPolicyKey {
  customPolicyKey: string;
  customPolicyLabel: string;
  customPolicyDescription: string;
  customPolicyUrl: string;
  compliantPipelineCount: number;
  notCompliantPipelineCount: number;
  noDataAvailableCount: number;
  customPolicyText?: string;
}

export interface PolicyKeyGroupDetails {
  pipelineId: string;
  pipelineKey: string;
  pipelineUrl: string;
  policyId: number;
  policyKey: string;
  policyVersion: string;
  policyLabel: string;
  policyDescription: string;
  policyOwner: string;
  policyOwnerSubstitute: string;
  policyUrl: string;
  bundleRevision: string;
  pipelineRunId: number;
  buildUrl: string;
  policyResultId: number;
  subPath: string;
  fileName: string;
  toolName?: string;
  validationErrorMessages: string[];
  policyComplianceStatusId: number;
  policyComplianceStatus: string;
  policyText?: string;
  isIgnored: boolean;
  ignoreReason?: string;
}

export interface CustomPolicyKeyGroupDetails {
  pipelineId: string;
  pipelineKey: string;
  pipelineUrl: string;
  customPolicyId: number;
  customPolicyKey: string;
  customPolicyVersion: string;
  customPolicyLabel: string;
  customPolicyDescription: string;
  customPolicyAuthor: string;
  customPolicySourceUrl: string;
  pipelineRunId: number;
  buildUrl: string;
  customPolicyResultId: number;
  subPath: string;
  fileName: string;
  validationErrorMessages: string[];
  customPolicyResultStatusId: number;
  customPolicyResultStatus: string;
  customPolicyText?: string;
  customPolicyComplianceStatus: CustomPolicyComplianceStatus;
}
