import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ComboBoxModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { PopoverModule } from '@progress/kendo-angular-tooltip';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import {
  ButtonModule,
  ChipsModule,
  FEATURE_TRACKER_INIT_MATOMO,
  FEATURE_TRACKER_MATOMO_APPLICATION_ID,
  FEATURE_TRACKER_MATOMO_ENDPOINT,
  FEATURE_TRACKER_MATOMO_IS_IFRAME,
  FeatureUsageTrackerModule,
  IconModule,
  MatomoService,
  TooltipModule,
} from '@tools/fusion';
import { CumulusAuthInterceptorService } from '../cumulus-auth-interceptor/cumulus-auth-interceptor.service';
import { environment } from '../environments/environment';
import { KpisModule } from './KPIs/kpis.module';
import { AddPipelineDialogComponent } from './add-pipeline-dialog/add-pipeline-dialog.component';
import { AnnouncementBannerComponent } from './announcement-banner/announcement-banner.component';
import { AppComponent } from './app.component';
import { ChangePipelineRunReleaseStatusDialogComponent } from './change-pipeline-run-release-status-dialog/change-pipeline-run-release-status-dialog.component';
import { en } from './i18n/en';
import { NgrxModule } from './ngrx/ngrx.module';
import { PipelineListItemDetailComponent } from './pipeline-list-item-detail/pipeline-list-item-detail.component';
import { GroupComplianceStatusComponent } from './pipeline-list/group-compliance-status/group-compliance-status.component';
import { MultiCheckFilterComponent } from './pipeline-list/multicheck-filter/multicheck-filter.component';
import { PipelineListComponent } from './pipeline-list/pipeline-list.component';
import { RunHeaderInfoComponent } from './pipeline-list/run-header-info/run-header-info.component';
import { EnvironmentService } from './shared/environment.service';
import { SharedModule } from './shared/shared.module';
import { StartScreenComponent } from './start-screen/start-screen.component';

@NgModule({
  declarations: [
    AppComponent,
    AddPipelineDialogComponent,
    ChangePipelineRunReleaseStatusDialogComponent,
    StartScreenComponent,
    PipelineListComponent,
    PipelineListItemDetailComponent,
    RunHeaderInfoComponent,
    GroupComplianceStatusComponent,
    MultiCheckFilterComponent,
    AnnouncementBannerComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    TreeListModule,
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule,
    GridModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    FeatureUsageTrackerModule,
    ComboBoxModule,
    ButtonModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatTableModule,
    TooltipModule,
    FormsModule,
    MatDialogModule,
    MatIconModule,
    DropDownsModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    IconModule,
    MatChipsModule,
    NgrxModule,
    MatTabsModule,
    KpisModule,
    MatSnackBarModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
    }),
    SharedModule,
    ChipsModule,
    InputsModule,
    ScrollingModule,
    MatButtonModule,
    LabelModule,
    PopoverModule,
    NgOptimizedImage,
  ],
  providers: [
    { provide: FEATURE_TRACKER_MATOMO_ENDPOINT, useValue: 'https://tools-matomo.c.eu-de-2.cloud.sap/' },
    { provide: FEATURE_TRACKER_MATOMO_APPLICATION_ID, useValue: environment.matomoApplicationId },
    { provide: FEATURE_TRACKER_INIT_MATOMO, useValue: false },
    { provide: FEATURE_TRACKER_MATOMO_IS_IFRAME, useValue: true },
    { provide: HTTP_INTERCEPTORS, useClass: CumulusAuthInterceptorService, multi: true },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { enterAnimationDuration: 0, exitAnimationDuration: 0 } },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly matomoService: MatomoService,
    private readonly translateService: TranslateService,
  ) {
    this.translateService.setTranslation('en', en);
    this.environmentService.loadEnvironmentData().then(
      (data) => {
        this.matomoService.injectMatomo('https://tools-matomo.c.eu-de-2.cloud.sap/', data.matomoApplicationId, true);
      },
      () => {
        throw new Error("Couldn't load Application configuration");
      },
    );
  }
}
