import {
  JiraAllComponentPipelineMapping,
  JiraComponentPipelineMapping,
  ReleaseDecisionInformation,
} from '../../../shared/access-api/access-api.service';

export const getAllJiraComponentPipelineMappingsFlat = (
  configurations: ReleaseDecisionInformation[],
): JiraAllComponentPipelineMapping[] => {
  const allPipelineMappings: JiraAllComponentPipelineMapping[] = [];
  // create a unique entry for each component-pipelineKey combination
  configurations.forEach((config) => {
    config.jiraComponentPipelineMappings?.forEach((mapping) =>
      mapping.pipelineKeys.forEach((pipelineKey) => {
        // Add if the same component-pipelineKey entry does not already exist
        if (!allPipelineMappings.find((entry) => entry.pipelineKey === pipelineKey && entry.component === mapping.component)) {
          allPipelineMappings.push({ pipelineKey: pipelineKey, component: mapping.component });
        }
      }),
    );
  });

  return allPipelineMappings;
};

export const getAllJiraComponentPipelineMappings = (configurations: ReleaseDecisionInformation[]): JiraComponentPipelineMapping[] => {
  const allPipelineMappings: JiraComponentPipelineMapping[] = [];
  // create a unique entry for each component-pipelineKey combination
  configurations.forEach((config) => {
    config.jiraComponentPipelineMappings?.forEach((mapping) => {
      // Add if the same component-pipelineKey entry does not already exist
      const existing = allPipelineMappings.find((entry) => entry.component === mapping.component);
      if (!existing) {
        allPipelineMappings.push({ pipelineKeys: mapping.pipelineKeys, component: mapping.component });
      } else {
        existing.pipelineKeys = [...new Set(existing.pipelineKeys.concat(mapping.pipelineKeys))];
      }
    });
  });
  return allPipelineMappings;
};
