import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { GroupPolicyUsageByPolicyKey } from '../../../ngrx/model/policy-results';
import { loadCentralPolicyUsageGroupDetails } from '../../../ngrx/store/central-policy-usage/central-policy-usage.actions';
import { selectCentralPolicyUsageGroupDetails } from '../../../ngrx/store/central-policy-usage/central-policy-usage.selectors';
import {
  selectIsCentralPolicyUsageGroupDetailsLoading,
  selectIsCentralPolicyUsageGroupDetailsLoadingError,
} from '../../../ngrx/store/loading-state/loading-state.selectors';
import { GroupComplianceMode } from '../../../shared/model/group-compliance-mode';
import { GridDataResult, MultipleSortSettings } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';

@Component({
  selector: 'app-policy-usage-aggregation-details',
  templateUrl: './policy-usage-aggregation-details.component.html',
  styleUrls: ['./policy-usage-aggregation-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyUsageAggregationDetailsComponent {
  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;

  @Output() selectedPolicy = new EventEmitter<GroupPolicyUsageByPolicyKey>();

  public dataSource$!: Observable<GridDataResult>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;

  policyUsageGroupSorted: GroupPolicyUsageByPolicyKey[];
  dataSource: GridDataResult;

  gridState: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [
      {
        field: 'policyText',
        dir: 'asc',
      },
    ],
  };

  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'asc',
    allowUnsort: true,
    showIndexes: true,
  };

  constructor(private readonly store$: Store) {
    this.dataSource$ = this.store$.select(selectCentralPolicyUsageGroupDetails).pipe(
      map((data) => {
        this.policyUsageGroupSorted = data;
        this.dataStateChanged(this.gridState);
        return this.dataSource;
      }),
    );
    this.isLoading$ = this.store$.select(selectIsCentralPolicyUsageGroupDetailsLoading);
    this.isLoadingError$ = this.store$.select(selectIsCentralPolicyUsageGroupDetailsLoadingError);
  }

  dataStateChanged(state) {
    this.gridState = state;
    this.dataSource = process(this.policyUsageGroupSorted, this.gridState);
  }

  reload() {
    this.store$.dispatch(
      loadCentralPolicyUsageGroupDetails({
        groupId: this.groupId,
        groupComplianceMode: this.groupComplianceMode,
      }),
    );
  }
}
