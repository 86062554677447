<div
  *ngIf="{
    isLoading: isLoading$ | async,
    isLoadingError: isLoadingError$ | async,
    dataSource: dataSource$ | async,
  } as data"
  class="flex flex-col"
  mat-dialog-content
>
  <div class="flex h-full w-full">
    <app-dialog-error-information (reloadClicked)="reload()" *ngIf="data?.isLoadingError"></app-dialog-error-information>
    <kendo-treelist
      *ngIf="!data?.isLoadingError"
      [filter]="gridState.filter"
      [filterable]="true"
      [resizable]="true"
      [sort]="gridState.sort"
      [sortable]="true"
      [kendoTreeListHierarchyBinding]="dataSource.data"
      childrenField="subPolicies"
      kendoTreeListExpandable
      data-test-id="policy-results-detail-popup-grid"
      class="flex h-full w-full"
      id="policy-results-detail-grid"
      [trackBy]="trackByResultId"
    >
      <kendo-treelist-column
        [minResizableWidth]="160"
        field="policyText"
        title="Policy"
        [width]="200"
        [expandable]="true"
        class="vertical-align-baseline-middle td-padding-top-20"
      >
        <ng-template kendoTreeListCellTemplate kendoTooltip let-dataItem>
          <div class="treelist-first-cell-wrapper">
            <div class="align-middle">
              <div
              matBadge="{{ dataItem.subPolicies?.length }}"
              matBadgeSize="small"
              matBadgeOverlap="false"
              class="badge-container"
              [matBadgeHidden]="!dataItem.subPolicies"
            >
              <a [fusionTooltip]="'Open Policy'" href="{{ dataItem?.policyUrl }}" rel="noopener noreferrer" target="_blank">
                <span data-test-id="policy-result-policy-key" class="bold">{{ dataItem.policyKey }}</span>
                <i class="fi fi-link-external bold policyExternalLinkIcon"></i>
              </a>
            </div>
          </div>
            <div class="policyLabel" data-test-id="policy-result-policy-label">{{ dataItem.policyLabel }}</div>
        </div>
        </ng-template>
      </kendo-treelist-column>

      <kendo-treelist-column
        *ngIf="showToolNameColumn"
        [minResizableWidth]="100"
        field="toolName"
        title="Measurement Tool"
        [width]="140"
        class="vertical-align-top td-padding-top-20"
      >
        <ng-template kendoTreeListCellTemplate kendoTooltip let-dataItem>
          <div class="align-middle">
            <span data-test-id="policy-result-tool-name">{{ dataItem?.toolName || '&mdash;' }}</span>
          </div>
        </ng-template>
      </kendo-treelist-column>

      <kendo-treelist-column
        [filterable]="false"
        [minResizableWidth]="200"
        field="validationErrorMessages"
        title="Validation Error Messages"
        [width]="300"
        class="vertical-align-top td-padding-top-20"
      >
        <ng-template kendoTreeListCellTemplate kendoTooltip let-dataItem let-rowIndex="rowIndex">
          <app-validation-error-message-list
            [messages]="dataItem?.validationErrorMessages"
            messagesTestId="policy-result-validation-error-messages"
            id="textTruncationBlock{{ rowIndex }}"
            data-test-id="policy-result-validation-error-messages"
          >
          </app-validation-error-message-list>
        </ng-template>
      </kendo-treelist-column>

      <kendo-treelist-column
        [minResizableWidth]="77"
        [maxResizableWidth]="200"
        field="policyComplianceStatus.displayKey"
        title="Status"
        [width]="160"
        class="vertical-align-top td-padding-top-20"
      >
        <ng-template kendoTreeListCellTemplate kendoTooltip let-dataItem>
          <div class="truncate align-middle" appTextTruncateTitleTooltip>
            <span
              *ngIf="dataItem?.policyComplianceStatus?.displayKey"
              class="status-circle status-circle-{{ dataItem?.policyComplianceStatus?.key }}"
            >
            </span>
            <span data-test-id="policy-result-compliance-status">{{ dataItem?.policyComplianceStatus?.displayKey || '&mdash;' }}</span>
          </div>
        </ng-template>
      </kendo-treelist-column>

      <kendo-treelist-column
        [minResizableWidth]="100"
        [maxResizableWidth]="100"
        [filterable]="false"
        [sortable]="false"
        title=""
        [width]="100"
        class="vertical-align-top td-padding-top-20"
      >
        <ng-template kendoTreeListCellTemplate kendoTooltip let-dataItem>
          <div>
            <div class="flex flex-row gap-2.5 justify-center items-baseline">
              <button
                fusionIconButton
                fusionIcon="mail"
                fusionIconSize="L"
                (click)="mailTo(dataItem)"
                [fusionTooltip]="mailToTooltip"
                class="align-middle"
                data-test-id="mailToPolicyResponsiblesButton"
                fusionFeatureUsageTracker="policy-results-mail-to-btn"
              ></button>
              <ng-template #mailToTooltip>
                <div class="action-tooltip-header">Contact Central Policy Responsibles</div>
                <div class="action-tooltip-text">Owner: {{ dataItem.policyOwner }}</div>
                <div class="action-tooltip-text">Substitute: {{ dataItem.policyOwnerSubstitute }}</div>
              </ng-template>
              <button
                fusionIconButton
                fusionIcon="download"
                fusionIconSize="L"
                (click)="downloadFile(dataItem)"
                [fusionTooltip]="downloadPolicyResultFileButton"
                class="align-middle"
                data-test-id="downloadPolicyResultFileButton"
                fusionFeatureUsageTracker="download-policy-results-file-btn"
              ></button>
              <ng-template #downloadPolicyResultFileButton>
                <div class="action-tooltip-header">Download Policy Result File</div>
                <div class="action-tooltip-text">{{ getDownloadTooltipText(dataItem?.fileName, dataItem?.subPath) }}</div>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </kendo-treelist-column>
    </kendo-treelist>
    <app-table-loading-indicator *ngIf="data.isLoading" class="custom-loading-indicator-position"></app-table-loading-indicator>
  </div>
</div>
